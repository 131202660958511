import { generateImageUrl } from '@capturi/request'
import { Avatar, Box, Td, Text, Tooltip } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React, { ReactNode } from 'react'

type Props = {
  name: string
  email: string
  profileImage: {
    key: string
    type: string
  } | null
  title: string
  mostRecentConversation: Date | null
  lastTokenRefresh: Date | null
}
const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

const lastConversationField = (date: Date | null): ReactNode => {
  if (date == null) return null

  return (
    <Tooltip
      openDelay={500}
      hasArrow
      shouldWrapChildren
      label={t`Last conversation imported at ${i18n.date(
        date,
        dateFormatOptions,
      )}`}
    >
      <Trans>Conversation: {i18n.date(date, dateFormatOptions)}</Trans>
    </Tooltip>
  )
}
const lastActivityField = (date: Date | null): ReactNode => {
  if (date == null) return null

  return (
    <Tooltip
      openDelay={500}
      hasArrow
      shouldWrapChildren
      label={t`User last seen at ${i18n.date(date, dateFormatOptions)}`}
    >
      <Trans>Active: {i18n.date(date, dateFormatOptions)}</Trans>
    </Tooltip>
  )
}

const NameCell: React.FC<Props> = ({
  email,
  name,
  profileImage,
  title,
  mostRecentConversation,
  lastTokenRefresh,
}) => {
  const dividerString = mostRecentConversation && lastTokenRefresh ? ' | ' : ''

  return (
    <Td maxW="200px">
      <Box alignItems="center" display="flex">
        <Avatar
          size="xs"
          src={generateImageUrl(profileImage?.key, {
            size: 32,
          })}
          name={name}
          mr={4}
          display={['none', null, 'inline-flex']}
        />
        <Box noOfLines={1} wordBreak="break-all">
          <Text fontWeight="medium" noOfLines={1} wordBreak="break-all">
            {name}
          </Text>
          <Text
            fontSize="xs"
            color="textMuted"
            noOfLines={1}
            wordBreak="break-all"
          >
            {title}
          </Text>
          <Text
            fontWeight="medium"
            fontSize="xs"
            color="textMuted"
            noOfLines={1}
            wordBreak="break-all"
          >
            {email}
          </Text>

          <Text fontSize="xs" color="textMuted">
            {lastConversationField(mostRecentConversation)}
            {dividerString}
            {lastActivityField(lastTokenRefresh)}
          </Text>
        </Box>
      </Box>
    </Td>
  )
}

export default NameCell
