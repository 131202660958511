import tracker from '@capturi/analytics'
import { InviteStatus } from '@capturi/core'
import { getErrorMessage } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import {
  Divider,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import { PatchUser } from 'pages/Organization/hooks/useUserActions'
import React from 'react'
import { BiBlock, BiWindowClose } from 'react-icons/bi'
import {
  MdDelete,
  MdEdit,
  MdMailOutline,
  MdMergeType,
  MdMoreVert,
  MdOutlineArrowForward,
} from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import { useBackSyncTeam } from '../../hooks/useBackSyncTeam'
import EditUserDrawer from '../EditUserDrawer'
import DeleteUserModal from '../modals/DeleteUserModal'
import { InviteUserFromEmailDialog } from '../modals/InviteUserFromEmailModal'
import MergeUsersDialog from '../modals/MergeUsersModal'
import moveCasesDialog from '../modals/MoveCasesDialog'

type Props = {
  inviteUser: (userUid: string, email: string) => Promise<void>
  unassignUser: (userUid: string) => Promise<void>
  cancelUserDeletion: (userUid: string) => Promise<void>
  patchUser: PatchUser
  userUid: string
  inviteStatus: string
  isScheduledForDeletion: boolean
  name: string
  title: string
  teamUid: string | undefined
  teamName: string | undefined
  currentEmail: string
  isDrawerIdOpen: boolean
  setIsDrawerIdOpen: React.Dispatch<React.SetStateAction<string>>
}
const SettingsCell: React.FC<Props> = ({
  inviteUser,
  unassignUser,
  cancelUserDeletion,
  patchUser,
  userUid,
  inviteStatus,
  isScheduledForDeletion,
  teamUid,
  teamName,
  name,
  title,
  currentEmail,
  isDrawerIdOpen,
  setIsDrawerIdOpen,
}) => {
  const { disableUserConfig, hideInviteOptions, enableText } = useFeatureFlags()
  const [openEmailInviteModal] = useModal(InviteUserFromEmailDialog)
  const [openDeleteUserModal] = useModal(DeleteUserModal)
  const [openMoveCasesModal] = useModal(moveCasesDialog)
  const [openMergeUserDialog] = useModal(MergeUsersDialog)
  const backSyncUserTeam = useBackSyncTeam()
  const confirm = useConfirm()
  const toast = useToast({ duration: 10000 })

  const handleInviteUser = (): void => {
    openEmailInviteModal({
      userUid,
      currentEmail,
      name,
      invite: inviteUser,
      onSubmit: () => {
        toast({
          title: t`${name} is now invited, wuhuu!`,
          status: 'success',
        })
      },
    })
  }

  const handleCancelUserDeletion = async (): Promise<void> => {
    try {
      await cancelUserDeletion(userUid)
      toast({
        title: t`User will no longer be deleted`,
        status: 'success',
      })
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      toast({
        title: 'Error cancelling user deletion. Sorry!',
        description: errorMsg,
        status: 'error',
      })
    }
  }

  const handleUnassignUser = async (): Promise<void> => {
    await confirm({
      title: t`Remove access/login`,
      description: t`Are you sure you want to remove access/login from ${name}?`,
      cancelText: t`Cancel`,
      confirmText: t`Confirm`,
    })
      .then(async () => {
        try {
          tracker.event('organizationPage_userUnassigned')

          await unassignUser(userUid)
          toast({
            title: t`${name} is no longer active`,
            status: 'success',
          })
        } catch (error) {
          const errorMsg = getErrorMessage(error)

          toast({
            title: t`Could not remove access/login, sorry`,
            description: errorMsg,
            status: 'error',
          })
        }
      })
      .catch(noop)
  }

  return (
    <Td w="5%">
      <Menu>
        <MenuButton color="gray.400" display="block">
          <Icon as={MdMoreVert} boxSize={5} />
        </MenuButton>
        <MenuList lineHeight="1.5">
          {!disableUserConfig && (
            <MenuItem onClick={() => setIsDrawerIdOpen(userUid)}>
              <Icon as={MdEdit} />
              <Text ml="2" fontSize="md">
                <Trans>Edit user</Trans>
              </Text>
            </MenuItem>
          )}
          {!hideInviteOptions && (
            <MenuItem
              onClick={handleInviteUser}
              isDisabled={inviteStatus === InviteStatus.accepted}
            >
              <Icon as={MdMailOutline} />
              <Text ml="2" fontSize="md">
                {inviteStatus === 'sent' ? (
                  <Trans>Resend invitation</Trans>
                ) : (
                  <Trans>Invite user</Trans>
                )}
              </Text>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              openMergeUserDialog({
                userUid: userUid,
              })
              tracker.event('merge_user_dialog_show')
            }}
          >
            <Icon as={MdMergeType} />
            <Text ml="2" fontSize="md">
              <Trans>Merge user</Trans>
            </Text>
          </MenuItem>
          {!hideInviteOptions && (
            <MenuItem
              onClick={handleUnassignUser}
              isDisabled={inviteStatus !== InviteStatus.accepted}
            >
              <Icon as={BiBlock} />
              <Text ml="2" fontSize="md">
                <Trans>Remove access/login</Trans>
              </Text>
            </MenuItem>
          )}
          {!hideInviteOptions && (
            <>
              {isScheduledForDeletion ? (
                <MenuItem onClick={() => handleCancelUserDeletion()}>
                  <Icon as={BiWindowClose} />
                  <Text ml="2" fontSize="md">
                    <Trans>Undo scheduled deletion</Trans>
                  </Text>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    openDeleteUserModal({ userUid, name })
                    tracker.event('organization_users_openDeleteUserModal')
                  }}
                >
                  <Icon as={MdDelete} />
                  <Text ml="2" fontSize="md">
                    <Trans>Delete user</Trans>
                  </Text>
                </MenuItem>
              )}
            </>
          )}
          <Divider />
          <MenuItem
            isDisabled={!teamUid}
            onClick={() =>
              teamUid && teamName
                ? backSyncUserTeam(userUid, name, teamUid, teamName)
                : undefined
            }
          >
            <Icon as={MdOutlineArrowForward} />
            <Text ml="2" fontSize="md">
              <Trans>Move conversations to current team</Trans>
            </Text>
          </MenuItem>
          {enableText && (
            <MenuItem
              isDisabled={!teamUid}
              onClick={() =>
                teamUid && teamName
                  ? openMoveCasesModal({
                      userUid,
                      newTeamUid: teamUid,
                      teamName,
                    })
                  : undefined
              }
            >
              <Icon as={MdOutlineArrowForward} />
              <Text ml="2" fontSize="md">
                <Trans>Move tickets to current team</Trans>
              </Text>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <EditUserDrawer
        name={name}
        title={title}
        email={currentEmail}
        userUid={userUid}
        isOpen={isDrawerIdOpen}
        onClose={() => setIsDrawerIdOpen('')}
        patchUser={patchUser}
      />
    </Td>
  )
}

export default SettingsCell
