import tracker from '@capturi/analytics'
import { useActionToast, useToast } from '@capturi/ui-components'
import { OnChangeValue, Select, SelectOptionBase } from '@capturi/ui-select'
import { Td } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useState } from 'react'

import { useFeatureFlags } from '@capturi/feature-flags'
import { useBackSyncTeam } from '../../hooks/useBackSyncTeam'
import { PatchUser } from '../../hooks/useUserActions'
import { Team } from '../../types'

const formatOption = (team: {
  uid: string
  name: string
}): {
  value: string
  label: string
} => ({
  value: team.uid,
  label: team.name,
})

type Props = {
  teams: Team[]
  team: Team | null
  width?: string
  patchUser: PatchUser
  currentUserUid: string
  userUid: string
  name: string
}

const TeamCell: React.FC<Props> = ({
  team,
  teams,
  width,
  userUid,
  patchUser,
  name,
}) => {
  const { disableUserConfig } = useFeatureFlags()

  const { uid: teamUid, name: teamName } = team || {}
  const toast = useToast()
  const actionToast = useActionToast()
  const backSyncUserTeam = useBackSyncTeam()

  const [inProgress, setInProgress] = useState<boolean>(false)

  const handleChange = async (
    e: OnChangeValue<SelectOptionBase, false>,
  ): Promise<void> => {
    if (e == null) return

    try {
      tracker.event('organizationPage_teamChange')
      setInProgress(true)
      await patchUser(userUid, { teamUid: e.value || null })
      actionToast({
        title: t`${name} is now on team ${e.label}.`,
        description: t`Do you want to move existing conversations to the team`,
        status: 'success',
        actionText: t`Move Conversations`,
        onActionClick: () => backSyncUserTeam(userUid, name, e.value, e.label),
      })
    } catch (error) {
      let errMsg: null | string = null
      if (error instanceof Error) {
        errMsg = error.message
      }

      toast({
        title: t`Could not update the user's team`,
        description: errMsg,
        status: 'error',
      })
    } finally {
      setInProgress(false)
    }
  }

  const value: SelectOptionBase | null = teamUid
    ? { label: teamName ?? '', value: teamUid }
    : null
  const options: SelectOptionBase[] = [
    { value: '', label: t`Select team ...` },
    ...teams.map(formatOption),
  ]

  return (
    <Td width={width}>
      <Select
        isDisabled={inProgress || disableUserConfig}
        value={value}
        options={options}
        isMulti={false}
        placeholder={t`Select team ...`}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </Td>
  )
}

export default TeamCell
